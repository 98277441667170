import request from '@/utils/request'

  // 提交订单 createOrder
  export function createOrder(data) {
    return request({
      url: 'createOrder',
      method: 'post',
      data:data
    })
  } 
  //查询 detail
  export function detail(data) {
    return request({
      url: 'detail',
      method: 'post',
      data:data
    })
  } 
  //获取key  verifyCode
  export function verifyCode() {
    return request({
      url: 'verifyCode',
      method: 'get',
    })
  } 
  //获取openId   queryOrderPaid/:order_sn
  export function getOpenId(params) {
    return request({
      url: 'getOpenId',
      method: 'get',
      params:params
    })
  } 
  //查询支付状态
  export function orderPaid(order_sn) {
    return request({
      url: 'queryOrderPaid/'+order_sn,
      method: 'get',
    })
  } 
  //查询物流信息 express/:order_sn
  export function express(order_sn) {
    return request({
      url: 'express/'+order_sn,
      method: 'get',
    })
  } 


//********************中秋提货卡 */
  export function checkCard(card_no) {
    return request({
      url: 'checkGiftCardNo/'+card_no,
      method: 'get',
    })
  } 

  //商品列表
  export function productList(type) {
    return request({
      url: 'product_lst/'+type,
      method: 'get',
    })
  } 

  //商品提货时间
  export function giftTime(id) {
    return request({
      url: 'product_time/'+id,
      method: 'get'
    })
  } 

  //创建提货卡核销订单
  export function createGiftOrder(data) {
    return request({
      url: 'createGiftOrder',
      method: 'post',
      data:data
    })
  } 

  //查询 detail
  export function giftDetail(data) {
    return request({
      url: 'giftDetail',
      method: 'post',
      data:data
    })
  } 
      //查询物流信息 express/:order_sn
  export function giftExpress(order_sn) {
    return request({
      url: 'giftExpress/'+order_sn,
      method: 'get',
    })
  } 

  //跳转到微信小程序
  export function h5ToRoutierScheme() {
    return request({
      url: 'h5ToRoutierScheme',
      method: 'post',
    })
  } 