import axios from "axios";
// import { Message, MessageBox } from "element-ui";
// import Cookies from "js-cookie";
import { getToken, removeToken } from "@/utils/auth";

// 创建axios实例
const service = axios.create({
  baseURL: 'https://hexiao.ystyk.cn/api/', // api的base_url
  timeout: 15000, // 请求超时时间
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authori-zation"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
     
    }
    // config.withCredentials=true
    return config;
  },
  (error) => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    // 401:未登录;
    if ([410000, 410001, 410002].indexOf(res.status) !== -1) {
      removeToken();
      window.location.href = "/login";
    }
    if (res.status !== 200 && res.status !== 401) {
      return Promise.reject(res.msg);
    } else {
      return res;
    }
  },
  (error) => {
    // console.log('err' + error)// for debug
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 3 * 1000,
    // });
    return Promise.reject(error);
  }
);

export default service;
